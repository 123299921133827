import ReactGA from 'react-ga'
import axios from 'axios'
import { notification } from 'antd'

const axiosInterceptor = axios.create({
  baseURL: process.env.REACT_APP_NERD_URL
})

function openErrorNotification(description) {
  notification[`error`]({
    message: `Error`,
    description,
  })
}

const isHandlerEnabled = (config = {}) => {
  return config.hasOwnProperty('handlerEnabled') && !config.handlerEnabled 
    ? false 
    : true
}

const requestHandler = (request) => {
  const user = JSON.parse(localStorage.getItem(`user`))

  if (isHandlerEnabled(request)) {
    request.headers[`Authorization`] = `Bearer ${(user || {}).Token}`
  }

  return request
}

axiosInterceptor.interceptors.request.use(
  request => requestHandler(request)
)

const errorHandler = (error) => {
  if (isHandlerEnabled(error.config)) {
    switch (error.response.status) {
      case 401:
        if (error.response.data && error.response.data.error && error.response.data.error.length > 0) {
          openErrorNotification(error.response.data.error)          
        } else {
          openErrorNotification(`The authentication token has expired. Please log in again.`)
        }
        ReactGA.event({category: 'Authentication', action: 'Authentication token expired'})
        break
      default:
        if (error.response.data && error.response.data.error && error.response.data.error.length > 0) {
            openErrorNotification(error.response.data.error)            
        } else {
            openErrorNotification(`An error occurred while requesting data. Please try again.`)   
        }
        ReactGA.event({category: 'Backend Call', action: 'Error occurred while requesting data'})
        break
    }
  }
  return Promise.reject({ ...error })
}

const successHandler = (response) => {
  if (isHandlerEnabled(response.config)) {
    // Handle responses
  }
  return response
}

axiosInterceptor.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error)
)

export {
  axiosInterceptor
}
