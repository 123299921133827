import React, { useState, useEffect } from 'react'
import ReactGA from 'react-ga'
import './revenue-protection.page.scss'
import { Tabs } from 'antd'
import { 
  ParametersComponent, 
  DetailsComponent,
  MatrixComponent,
  PriceSimulationComponent,
} from './components'

const { TabPane } = Tabs

export function RevenueProtectionPage({ 
  sidenav, 
  toc,
}) {
  const [activeTab, setActiveTab] = useState(`parameters`)
  const [summaryData, setSummaryData] = useState(null)
  const [detailsData, setDetailsData] = useState(null)

  let awfulTempHack = detailsData ? detailsData.awfulTempHack : false

  const enablePriceSimulation = process.env.REACT_APP_ENABLE_PRICE_SIMULATION && process.env.REACT_APP_ENABLE_PRICE_SIMULATION.toLowerCase() === "true" ? true : false

  useEffect(() => {
    ReactGA.pageview('/revenue-protection/' + activeTab) 
    window.scrollTo(0, 0)   
  }, [activeTab])

  return (
    <div 
      className="RevenueProtectionPage" 
      style={{ maxWidth: `calc(100vw - ${sidenav ? '304px' : '48px'})` }}
    >
      <Tabs 
        activeKey={activeTab} 
        renderTabBar={() => 
          <div className="tabs" style={{ left: sidenav ? 256 : 0 }}>
            <a 
              className="tab" 
              style={{ 
                borderColor: activeTab === "parameters" && '#1890ff',
                color: activeTab === "parameters" && '#1890ff'
              }} 
              onClick={() => setActiveTab("parameters")}
            >
              Parameters
            </a>
            <a 
              className="tab" 
              style={{ 
                borderColor: activeTab === "summary" && '#1890ff',
                color: activeTab === "summary" && '#1890ff'
              }} 
              disabled={!summaryData}
              onClick={() => setActiveTab("summary")}
            >
              Summary
            </a>
            <a 
              className="tab" 
              style={{ 
                borderColor: activeTab === "details" && '#1890ff',
                color: activeTab === "details" && '#1890ff'
              }} 
              disabled={!detailsData}
              onClick={() => setActiveTab("details")}
            >
              Details
            </a>
            <a 
              className="tab" 
              style={{ 
                borderColor: activeTab === "price-simulation" && '#1890ff',
                color: activeTab === "price-simulation" && '#1890ff'
              }} 
              disabled={
                !detailsData || !enablePriceSimulation
              }
              onClick={() => setActiveTab("price-simulation")}
            >
              Price Simulation
            </a>
          </div>
        }
      >
        <TabPane tab="Parameters" key="parameters">
          <ParametersComponent
            setActiveTab={setActiveTab}
            toc={toc}
            summaryData={summaryData}
            setSummaryData={setSummaryData}
            setDetailsData={setDetailsData}
          />
        </TabPane>
        <TabPane tab="Summary" key="summary">
          {summaryData &&
            <MatrixComponent 
              setActiveTab={setActiveTab}
              toc={toc}
              summaryData={summaryData}
              setDetailsData={setDetailsData}
              awfulTempHack={awfulTempHack}
            />
          }
        </TabPane>
        <TabPane tab="Details" key="details">
          {detailsData &&
            <DetailsComponent 
              toc={toc}
              detailsData={detailsData}
            />
          }
        </TabPane>
        {enablePriceSimulation &&
        <TabPane tab="Price Simulation" key="price-simulation">
          {detailsData &&
            <PriceSimulationComponent 
              toc={toc}
              detailsData={detailsData}
            />
          }
        </TabPane>}
      </Tabs>
    </div>
  )
}
