import React, { useState, useEffect } from 'react'
import './bar-chart.component.scss'

export function BarChartComponent({ 
  data,
}) {
  const [parsedData, setParsedData] = useState(null)

  useEffect(() => {
    const callingPoints = data.callingPoints
      .reduce((callingPoints, callingPoint) => {
        callingPoints[callingPoint.location.crs] = 0
        return callingPoints
      }, {})
    data.fareListSplit.slice(0, -2)
      .forEach((fare) => {
        fare.splits
          .forEach((splitFare) => {
            const departCrs = splitFare.origin.crs
            const departIndex = data.callingPoints
              .findIndex((callingPoint) => callingPoint.location.crs === departCrs)
            if (departIndex > 0 && departIndex < data.callingPoints.length - 1) {
              callingPoints[departCrs] += 1
            }
          })
      })

    setParsedData({
      xAxis: Object.keys(callingPoints),
      yAxis: [`100%`, `75%`, `50%`, `25%`, `0%`],
      bars: Object.values(callingPoints).map((callingPoint) => callingPoint / (data.fareListSplit.length - 2) * 100)
    })    
  }, [data])

  return parsedData &&
    <div className="RevenueProtectionPageBarChartComponent">
      <div className="y-axis">
        {parsedData.yAxis.map((value, index) => 
          <div className="value" key={index}>
            {value}
          </div> 
        )}
      </div>
      <div className="body">
        <div className="graph">
          {parsedData.bars.map((bar, index) => 
            <div className="bar" key={index}>
              <div 
                className="segment" 
                title={bar + '%'}
                style={{
                  'height': bar + '%'
                }}
              ></div>
            </div>  
          )}
        </div>
        <div className="x-axis">
          {parsedData.xAxis.map((value, index) => 
            <div className="value" key={index}>
              {value}
            </div> 
          )}
        </div>
      </div>
    </div>
}