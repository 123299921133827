import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { AppComponent } from '..'

export function RouterComponent() {
  return (
    <Router>
      <AppComponent />
    </Router>
  )
}
