import React, { useState, useEffect } from 'react'
import ReactGA from 'react-ga'
import './login.page.scss'
import { axiosInterceptor } from '../../core/axios-interceptor'
import { Card, Input, Icon, Checkbox, Button } from 'antd'
import moment from 'moment'

export function LoginPage({ 
  setUser, 
  setToc,
}) {
  const [parameters, setParameters] = useState({
    username: null,
    password: null,
    rememberMe: true,
  })
  const [loading, setLoading] = useState(false)

  function getId(username, toc) {
    let hash = 0, i = 0;
    while (i < username.length)
      hash = (hash << 5) - hash + username.charCodeAt(i++) | 0
    hash = Math.abs(hash)
    return `${toc.code}-${hash}`
  }

  function getData() {
    setLoading(true)
    axiosInterceptor
      .post(`/api/authenticate`, { 
        username: parameters.username, 
        password: parameters.password 
      })
      .then((response) => {
        setLoading(false)
        if (!response.data.error) parseData(response.data)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }

  function parseData(data) {
    const user = { ...data }
    const toc = { ...data.TocInfo[0] }
    const expiry = moment(moment().add(12, `hours`)).utc().format()
    setUser(user)
    setToc(toc)
    localStorage.setItem(`user`, JSON.stringify(user))
    localStorage.setItem(`toc`, JSON.stringify(toc))
    localStorage.setItem(`expiry`, JSON.stringify(expiry))
    ReactGA.ga('set', 'userId', getId(parameters.username, toc))
    ReactGA.ga('set', 'dimension1', toc.code)
    ReactGA.event({category: 'Authentication', action: 'Successful Login', label: toc.code})
  }

  return(
    <div className="LoginPage">
      <Card bordered={false}>
        <h3 style={{ textAlign: 'center' }}>
          Network Explorer Login
        </h3>
        <form 
          style={{ marginTop: 28 }}
          onSubmit={(e) => {
            e.preventDefault()
            getData()
          }}
        >
          <div>
            <Input 
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)', fontSize: 14 }} />}
              placeholder="Username" 
              onChange={(e) => {
                setParameters({
                  ...parameters,
                  username: e.target.value
                })
              }}
            />
          </div>
          <div style={{ marginTop: 16 }}>
            <Input 
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)', fontSize: 14 }} />}
              type="password"
              placeholder="Password" 
              onChange={(e) => {
                setParameters({
                  ...parameters,
                  password: e.target.value
                })
              }}
            />
          </div>
          <div style={{ marginTop: 23 }}>
            <Checkbox
              checked={parameters.rememberMe}
              onChange={(e) => {
                setParameters({
                  ...parameters,
                  rememberMe: e.target.checked
                })
              }}
            >
              Remember me
            </Checkbox>
          </div>
          <Button 
            type="primary"
            htmlType="submit"
            disabled={
              loading 
              || !parameters 
              || !parameters.username 
              || !parameters.password
            }
            style={{ 
              width: `100%`,
              marginTop: 15,
              marginBottom: 4,
            }}
            loading={loading}
          >
            Submit
          </Button>
        </form>
      </Card>
    </div>
  )
}