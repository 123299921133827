import React, { useState, useEffect } from 'react'
import ReactGA from 'react-ga'
import { Route, Redirect, useHistory, Switch } from 'react-router-dom'
import { NetworkExplorerPage, RevenueProtectionPage, TopActionsPage, LoginPage } from '../../../pages'
import { SidenavComponent, HeaderComponent, FooterComponent } from '..'
import './app.component.scss'
import moment from 'moment'

export function AppComponent() {
  const [sidenav, setSidenav] = useState(true)
  const [user, setUser] = useState(JSON.parse(localStorage.getItem(`user`)))
  const [toc, setToc] = useState(JSON.parse(localStorage.getItem(`toc`)))
  const history = useHistory()
  
  /*

  * Log user out & clear localStorage if token expired.
  */
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem(`user`))
    const toc = JSON.parse(localStorage.getItem(`toc`))
    const expiry = JSON.parse(localStorage.getItem(`expiry`))
    const now = moment().utc().format()    

    if (!user || !toc || !expiry || moment(now).isAfter(moment(expiry))) {
      localStorage.clear()
      setUser(null)
      setToc(null)
      history.replace(`/login`)
    }
  }, [])

  useEffect(() => {    
    if (!localStorage.getItem(`googleTrackingId`)){
      ReactGA.initialize(process.env.GOOGLE_TRACKING_ID)    
      localStorage.setItem(`googleTrackingId`, process.env.GOOGLE_TRACKING_ID)      
    }
  }, [])

  /*
  * Send Page Views to Google Analytics.
  */
  useEffect(() => {    
    ReactGA.pageview(window.location.pathname === '/' ? '/home' : window.location.pathname)
  }, [window.location.pathname])

  /*
  * Disable Sidenav if User not logged in.
  */
  useEffect(() => {
    setSidenav(!!user)
  }, [user])

  /*
  * Permissions Route Guard.
  */
 function PermissionsRoute(Component, extraProps, feature) {
    if (!user) return (props) => <Redirect to='/login' />
    return (props) => ((user||{}).Permissions||[]).includes(feature)
      ? <Component {...props} {...extraProps} /> 
      : <Redirect to='/' />
  }

  /*
  * Auth Route Guard.
  */
  function AuthRoute(Component, extraProps) {
    return (props) => user 
      ? <Component {...props} {...extraProps} /> 
      : <Redirect to='/login' />
  }

  /*
  * Unauth Route Guard.
  */
  function UnauthRoute(Component, extraProps) {
    return (props) => !user 
      ? <LoginPage {...props} {...extraProps} /> 
      : <Redirect to='/' />
  }

  return (
    <React.Fragment>
      <div className="AppComponent">
        {sidenav && user && 
          <SidenavComponent user={user} />
        }
        <div className="content" style={{ paddingLeft: sidenav ? 280 : 24 }}>
          <HeaderComponent 
            sidenav={sidenav} 
            setSidenav={setSidenav} 
            user={user}
            toc={toc} 
            setToc={setToc} 
          />
          <Switch>
            <Route 
              path="/login" 
              render={UnauthRoute(LoginPage, { setUser, setToc })} 
            />
            <Route  
              exact 
              path="/" 
              render={AuthRoute(NetworkExplorerPage, { user })} 
            />
            <Route  
              exact 
              path="/top-actions" 
              render={PermissionsRoute(TopActionsPage, { sidenav, toc }, `topActions`)} 
            />
            <Route  
              exact 
              path="/revenue-protection" 
              render={PermissionsRoute(RevenueProtectionPage, { sidenav, toc }, `revenueProtection`)} 
            />
            <Route
              render={AuthRoute(NetworkExplorerPage, { user })} 
            />
          </Switch>
          <FooterComponent />
        </div>
      </div>
    </React.Fragment>
  )
}
