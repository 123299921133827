import React, { useState, useEffect } from 'react'
import './sidenav.component.scss'
import { Link } from 'react-router-dom'
import { Menu } from 'antd'

const { SubMenu } = Menu

export function SidenavComponent({ 
  user, 
}) {
  const [theme, setTheme] = useState(`dark`)
  const [current, setCurrent] = useState(`1`)

  useEffect(() => {
    switch(window.location.pathname) {
      case `/`:
        setCurrent(`1`)
        break
      case `/top-actions`:
        setCurrent(`2`)
        break
      case `/revenue-protection`:
        setCurrent(`3`)
        break
      default:
        setCurrent(null)
        break
    }
  }, [window.location.pathname])
  
  return (
    <div className="SidenavComponent">
      <div className="logo">
        <img src="https://www.silverrailtech.com/wp-content/uploads/2016/12/silverrail-logo-03.png"></img>
        <div className="name">SILVERRAIL</div>
      </div>
      <Menu
        theme={theme}
        defaultOpenKeys={[`1`]}
        style={{ height: `100%` }}
        onClick={(e) => setCurrent(e.key)}
        selectedKeys={[current]}
        mode="inline"
      >
        <SubMenu
          key="1"
          title={
            <span>
              <span>Network Explorer</span>
            </span>
          }
        >
          <Menu.Item key="1">
            <Link to="/">Home</Link>
          </Menu.Item>
          <Menu.Item key="2" active disabled={!(user.Permissions||[]).includes("topActions")}>
            <Link to="/top-actions">Top Actions</Link>
          </Menu.Item>
          <Menu.Item key="3" active disabled={!(user.Permissions||[]).includes("revenueProtection")}>
            <Link to="/revenue-protection">Revenue Protection</Link>
          </Menu.Item>
          <Menu.Item key="4" disabled>
            Opportunity Finder
          </Menu.Item>
          <Menu.Item key="5" disabled>
            Route Expander
          </Menu.Item>
          <Menu.Item key="6" disabled>
            Timing Optimizer
          </Menu.Item>
          <Menu.Item key="7" disabled>
            Legal / Illegal Journey Re-modeller
          </Menu.Item>
          <Menu.Item key="8" disabled>
            Vampire Network Module
          </Menu.Item>
          <Menu.Item key="9" disabled>
            Automated Configuration Changer
          </Menu.Item>
        </SubMenu>
      </Menu>
    </div>
  )
}