import moment from 'moment'

export function parseBigNumber(value) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, `,`)
}

export function addHours(value) {
  const hours = (value / 60)
  const rhours = Math.floor(hours)
  const minutes = (hours - rhours) * 60
  const rminutes = Math.round(minutes)
  return `${rhours}h ${rminutes}m`
}

export function removeHours(value) {
  const hours = parseInt(value.split(/h/gi)[0])
  const minutes = parseInt(value.split(/ /gi)[1].split(/m/gi)[0])
  return hours * 60 + minutes
}

export function addPercentage(value) {
  if (value > 10000) return `-`
  return `${value.toFixed(2)}%`
}

export function removePercentage(value) {
  if (value === `-`) return Infinity
  return parseInt(value.replace(/%/gi, ``))
}

export function addPound(value) {
  if (value < 0) {
    value = Math.abs(value)
    return `-£${value.toFixed(2)}`
  }
  return `£${value.toFixed(2)}`
}

export function removePound(value) {
  return parseInt(value.replace(/£/gi, ``))
}

export function to24HourTime(value) {
  return moment(value).format(`HH:mm`)
}

export function calcPercentDiff(a, b) {
  if (!a && b) {
    return -100
  }

  if (a && !b) {
    return 100
  }

  if (a && b) {
    return Math.round(((a / b) - 1) * 100)
  }

  return 0
}

function getUniqueFares(fares) {
  return fares.reduce((uniqueFares, fare) => {
    if (!fare.id) uniqueFares.push(fare)
    else if (uniqueFares.findIndex(uniqueFare => uniqueFare.id === fare.id) === -1) {
      uniqueFares.push(fare)
    }
    return uniqueFares
  }, [])
}

export function getTotalCommission(fares) {
  const uniqueFares = getUniqueFares(fares)
  return uniqueFares.reduce((totalCommission, fare) => {
    return totalCommission + fare.commission
  }, 0)
}

export function getTotalPrice(fares) {
  const uniqueFares = getUniqueFares(fares)
  return uniqueFares.reduce((totalPrice, fare) => {
    return totalPrice + fare.price
  }, 0)
}