import React from 'react'
import './details.components.scss'
import { Card } from 'antd'
import {
  PieChartComponent,
  BarChartComponent,
  SplitFaresChartComponent,
} from './components'

export function DetailsComponent({
  toc,
  detailsData,
}) {
  return (
    <div className="RevenueProtectionPageDetailsComponent">
      {detailsData &&
        <div>
          <Card title="Split Fares Chart">
            <SplitFaresChartComponent
              detailsData={detailsData}
              toc={toc}
            />
          </Card>
          {detailsData.outwardData.fareListSplit &&
            <div 
              style={{ 
                display: `flex`, 
                margin: `24px -24px -24px 0` 
              }}
            >
              <Card
                title="Split Fares Average Revenue"
                style={{
                  width: `calc(33.3333% - 24px)`, 
                  margin: `0 24px 24px 0`
                }}
              >
                <PieChartComponent
                  data={detailsData.outwardData}
                />
              </Card>
              <Card
                title="Split Point Frequency"
                style={{ 
                  width: `calc(66.6666% - 24px)`, 
                  margin: `0 24px 24px 0` 
                }}
              >
                <BarChartComponent
                  detailsData={detailsData}                  
                />
              </Card>
            </div>
          }
        </div>
      }
    </div>
  )
}