import React from 'react'
import './price-simulation-chart.component.scss'
import TrainIcon from '@material-ui/icons/Train'
import { useState } from 'react'
import { Input } from 'antd'
import { 
  to24HourTime, 
  addPound,
} from '../../../../../../core/transformation-functions'

export function PriceSimulationChartComponent({
  detailsData,
  toc,
  modifyDetails,
  selectedFare,
}) {

  var doReturn = []
  var callingPoints = []
  var splitFaresData = []
  if(detailsData) {
    doReturn = !!detailsData.returnData
    callingPoints = parseCallingPoints()
    splitFaresData = parseSplitFares()
  } else {
    detailsData = { outwardData:{ callingPoints: [{trainUid:"none"}] }, returnData:{ callingPoints: [{trainUid:"none"}] }   }
    doReturn = []
    callingPoints = []
    splitFaresData = []
  }

  /*
  * Parse callingPoints depending if return fares exists.
  */
  function parseCallingPoints() {
    if (doReturn) {
      return [
        ...((detailsData.outwardData||{}).callingPoints||[]).slice(0, -1),
        ...((detailsData.returnData||{}).callingPoints||[]),
      ]
    } else {
      return [
        ...((detailsData.outwardData||{}).callingPoints||[])
      ]
    }
  }

  /*
  * Parse split fares depending if return fares exists.
  * Add Recommended Action fare.
  * Add direct fare.
  * Add relevant calling points to each fare.
  * Add fare type.
  */
  function parseSplitFares() {
    //console.log("the detaildata being turned into the split fares object")
    //console.log(detailsData)
    if (doReturn) {
      var splits = ((detailsData||{}).splitFares||[])
      .map(split => [
        ...((split.outwardFare||{}).splits||[]).map(fare => ({
          ...fare,
          callingPoints: ((detailsData.outwardData||{}).callingPoints||[]),
        })),
        ...((split.returnFare||{}).splits||[]).map(fare => ({
          ...fare,
          callingPoints: ((detailsData.returnData||{}).callingPoints||[]),
        })),
      ])
      return [
        [
          {
            ...((detailsData.directFares||[])[0]||{}),
            callingPoints: ((detailsData.outwardData||{}).callingPoints||[]),
            direct: true,
          },
          {
            ...((detailsData.directFares||[])[1]||{}),
            callingPoints: ((detailsData.returnData||{}).callingPoints||[]),
            direct: true,
          }
        ],
        ...splits.slice(0, splits.length > 3 ? 3 : splits.length-1)
      ]
        .filter((fares) => fares.length)
        .sort((a, b) => getTotalPrice(a) - getTotalPrice(b))
    } else {
      const directFare = ((detailsData.outwardData||{}).fareListDirect||[])[0]
      const recommendedAction = (detailsData.outwardData||{}).recommendedAction
      
      if (directFare && recommendedAction && recommendedAction.commission == 0) {
        let rate = 1
        if (directFare.price && recommendedAction.price) {
          rate = recommendedAction.price / directFare.price
        }
        recommendedAction.commission = directFare.commission * rate
      }

      const splits = ((detailsData.outwardData||{}).fareListSplit||[])
      .map(split => [
        ...(split.splits||[]).map(fare => ({
          ...fare,
          callingPoints: ((detailsData.outwardData||{}).callingPoints||[]),
        })),
      ])

      return [
        // Recommended Action
        [
          {
            ...(recommendedAction||{}),
            callingPoints: ((detailsData.outwardData||{}).callingPoints||[]),
            recommended: true
          }
        ],
        // Direct Fare
        [
          {
            ...(directFare||{}),
            callingPoints: ((detailsData.outwardData||{}).callingPoints||[]),
            direct: true,
          }
        ],
        // Split Fares
        ...splits.slice(0, splits.length > 3 ? 3 : splits.length-1)
      ]
        .filter((fares) => fares.length)
        .sort((a, b) => getTotalPrice(a) - getTotalPrice(b))
    }
  }

  /*
  * Check if fare is return ticket.
  */
  function isFareReturn(fare, fareIndex, fares) {
     if (!fare.id) return false
    const slicedFares = fares.slice(0, fareIndex)
    return slicedFares.find(slicedFare => slicedFare.id === fare.id)
  }

  /*
  * Calculation functions for bar segments.
  */
  function getSegmentHeight(fare) {
    const startIndex = fare.callingPoints.findIndex(cp => cp.location.crs === fare.origin.crs) + 1
    const endIndex = fare.callingPoints.findIndex(cp => cp.location.crs === fare.destination.crs) + 1
    const count = endIndex - startIndex
    return count * 80
  }

  function getSegmentBackground(fare) {
    var colour = null
    if(fare.fareSetter === `R.A.`){
      colour = `#2fc25b`
    }
    else if(isFareSelectedFare(fare)) {
      colour = `#00b3b3`
    }
    else if(toc.fareSetter === fare.fareSetter) {
      colour = `rgb(24, 144, 255)`
    }
    else {
      colour = `#f0f2f5`
    }

    return colour
  }

  function getSegmentColor(fare) {
    return toc.fareSetter === fare.fareSetter 
      || fare.fareSetter === `R.A.`
      ? `#fff`
      : null
  }

  /*
  * Get unique fares based on fare id.
  */
  function getUniqueFares(fares) {
    return fares.reduce((uniqueFares, fare) => {
      if (!fare.id) uniqueFares.push(fare)
      else if (uniqueFares.findIndex(uniqueFare => uniqueFare.id === fare.id) === -1) {
        uniqueFares.push(fare)
      }
      return uniqueFares
    }, [])
  }

  /*
  * Calculate prices based on unique split fares.
  */
  function getTotalPrice(fares) {
    const uniqueFares = getUniqueFares(fares)
    return uniqueFares.reduce((totalPrice, fare) => {
      return totalPrice + fare.price
    }, 0)
  }

  function getTotalCommission(fares) {
    const uniqueFares = getUniqueFares(fares)
    return uniqueFares.reduce((totalCommission, fare) => {
      return totalCommission + fare.commission
    }, 0)
  }

  function recalcFares(){
    let newData = detailsData
    if(newData && newData.directFares) {
      newData.directFares.forEach(directFare => {
        if(isFareSelectedFare(directFare)){
          directFare.price = selectedFare.price
        }
      });
    }

    if(newData.splitFares && newData.splitFares) {
      newData.splitFares.forEach(splitfares => {
        splitfares.outwardFare.splits.forEach(splitfare => {
          if(isFareSelectedFare(splitfare)){
            splitfare.price = selectedFare.price
          }
        });
      });
    }

    if(newData.splitFares && newData.splitFares) {
      newData.splitFares.forEach(splitfares => {
        splitfares.returnFare.splits.forEach(splitfare => {
          if(isFareSelectedFare(splitfare)){
            splitfare.price = selectedFare.price
          }
        });
      });
    }

    if(newData && newData.outwardData && newData.outwardData.fareListSplit) {
      newData.outwardData.fareListSplit.forEach(splitfares => {
        splitfares.splits.forEach(splitfare => {
          if(isFareSelectedFare(splitfare)){
            splitfare.price = selectedFare.price
          }
        });
      });
    }
    if(newData && newData.returnData && newData.returnData && newData.returnData.fareListSplit) {
      newData.returnData.fareListSplit.forEach(splitfares => {
        splitfares.splits.forEach(splitfare => {
          if(isFareSelectedFare(splitfare)){
            splitfare.price = selectedFare.price
          }
        });
      });
    }
    modifyDetails(newData)
  }

  function keyPressed(e) {
    if(e.key === 'Enter'){
      selectedFare.price = parseInt(e.target.value)
      recalcFares()
    }
  }

  function isFareSelectedFare(fare) {
    if(selectedFare){
      if(selectedFare.fareRoute == fare.fareRoute && selectedFare.fareTicketType == fare.fareTicketType && 
        selectedFare.origin.crs == fare.origin.crs && selectedFare.destination.crs == fare.destination.crs){
        return true
      }
    }
    return false
 }

  return (
    //<div></div>
    <div className="DetailsComponentSplitFaresChartComponent">
      <div //className="info"
      style={{
        height: '80px'
      }}>
        fare price: {selectedFare.price||"nothing"}, 
        route: {selectedFare.fareRoute||"nothing"}, 
        type: {selectedFare.fareTicketType||"nothing"}, 
        origin: {(selectedFare.origin||{}).crs||"nothing"},
        destination: {(selectedFare.destination||{}).crs||"nothing"},
        new price: 
        <Input
          style={{
            width: `10%`
          }}
          //value={selectedFare.price||"nothing"}
          // onSubmit={() => {
          //   changedInputValue("dummyvalue")
          // }}
          onKeyPress={(e) => {
            keyPressed(e)
          }}
        />
      </div>
      <div className="info">
        <div className="trip">
          <div className="journey">Outward:</div>
          <div className="icon">
            <TrainIcon />
          </div>
          <div className="type">{((detailsData.outwardData||{}).callingPoints||[])[0].trainUid}</div>
          <div className="toc">{(detailsData.outwardData||{}).mainServiceProvider}</div>
        </div>
        {doReturn &&
          <div className="trip">
            <div className="journey">Return:</div>
            <div className="icon">
              <TrainIcon />
            </div>
            <div className="type">{((detailsData.returnData||{}).callingPoints||[])[0].trainUid}</div>
            <div className="toc">{(detailsData.returnData||{}).mainServiceProvider}</div>
          </div>
        }
        <div style={{ flex: 1 }}></div>
        <div className="legend">
          {!doReturn &&
            <div className="key key-1">Recommended Action</div>
          }
          <div className="key key-2">Your Fare</div>
          <div className="key key-3">Competitor Fare</div>
        </div>
      </div>
      <div className="content">
        <div className="details">
          {callingPoints.map((cp, index) => 
            <div className="detail" key={index}>
              <div className="icon">
                {index === 0 || doReturn && index === ((detailsData.outwardData||{}).callingPoints||[]).length - 1
                  ? <TrainIcon /> 
                  : <div className="dot"></div>
                }
              </div>
              <div className="time">{to24HourTime(cp.departTime || cp.arrivalTime)}</div>
              <div className="station">{cp.location.crs}</div>
            </div>  
          )}
        </div>
        <div className="chart">
          <div className="graph">
            {splitFaresData.map((split, index) => 
              <div className="bar" key={index}>
                {split[0].direct && <div className="title">Direct</div>}
                {split[0].recommended && <div className="title">Recommended</div>}
                {split.map((fare, fareIndex) =>
                  <div 
                    className="segment" 
                    key={fareIndex}
                    style={{
                      height: getSegmentHeight(fare),
                      background: getSegmentBackground(fare),
                      color: getSegmentColor(fare),
                      opacity: isFareReturn(fare, fareIndex, split) ? `0.85` : `1`,
                      borderTop: isFareSelectedFare(fare) ? 'dashed' : 'none',
                      borderLeft: isFareSelectedFare(fare) ? 'dashed' : 'none',
                      borderRight: isFareSelectedFare(fare) ? 'dashed' : 'none',
                    }}
                  >
                    <div className="price">
                      {addPound(fare.price)}
                    </div>
                    {!isFareReturn(fare, fareIndex, split) &&
                      <div className="price">
                        [{addPound(fare.commission)}]
                      </div>
                    }
                    <div className="setter">
                      {fare.fareSetter !== `R.A.` &&
                        <React.Fragment>
                          <div>{fare.fareSetter}</div>
                          <div>
                            {fare.fareTicketType} ({fare.isReturn ? `R` : `S`} {fare.fareCategory === `Advance` ? `Adv.` : `WO.`})
                          </div>
                        </React.Fragment>
                      }
                    </div>
                  </div>  
                )}
              </div>
            )}
          </div>
          <div className="totals">
            {splitFaresData.map((split, index) => 
              <div className="total" key={index}>
                <div className="price">
                  {addPound(getTotalPrice(split))}
                </div>
                <div className="commission">
                  [{addPound(getTotalCommission(split))}]
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
