import React, { useState } from 'react'
import ReactGA from 'react-ga'
import './header.component.scss'
import { Icon, Dropdown, Menu, Button } from 'antd'

export function HeaderComponent({ 
  sidenav, 
  setSidenav, 
  user, 
  toc, 
  setToc,
}) {

  function handleReloadPage() {
    ReactGA.event({ category: 'Authentication', action: 'Logout', label : toc.code})
    localStorage.clear()
    window.location.reload()
  }

  function handleSetToc(tocInfo) {
    setToc(tocInfo)
    localStorage.setItem(`toc`, JSON.stringify(tocInfo))
    window.location.reload()
  }
  
  return (
    <div className="HeaderComponent" style={{ left: sidenav ? 256 : 0 }}>
      {user &&
        <Icon 
          type={sidenav ? `menu-fold` : `menu-unfold`} 
          onClick={() => setSidenav(!sidenav)} 
        />
      }
      {!user && 
        <img src="https://www.silverrailtech.com/wp-content/uploads/2016/12/silverrail-logo-03.png"></img>
      }
      <h3>{user && 'Network Explorer'}</h3>
      <div style={{ flex: 1 }}></div>
      {toc && user.TocInfo.length > 1 &&
        <Dropdown 
          overlay={
            <Menu>
              {user.TocInfo.map((tocInfo, index) =>
                <Menu.Item 
                  key={index} 
                  onClick={() => handleSetToc(tocInfo)}
                >
                  {tocInfo.code}
                </Menu.Item>
              )}
            </Menu>
          }
        >
          <Button size="small" style={{marginRight: "1em"}}>
              {toc.code} <Icon type="down" />
          </Button>
        </Dropdown>
      }
      {user && 
        <Dropdown 
          overlay={
            <Menu>
              <Menu.Item 
                key="1" 
                onClick={() => handleReloadPage()}
              >
                Logout
              </Menu.Item>
            </Menu>
          }
        >
          <Button size="small">
            {user.DisplayName} <Icon type="down" />
          </Button>
        </Dropdown>
      }
    </div>
  )
}