import React, { useState, useEffect } from 'react'
import ReactGA from 'react-ga'
import './parameters.component.scss'
import { Input, Card, DatePicker, Checkbox, Button, notification, AutoComplete } from 'antd'
import moment from 'moment'
import { axiosInterceptor } from '../../../../core/axios-interceptor'
import { useLocation } from "react-router"

export function ParametersComponent({
  summaryData,
  setSummaryData,
  setDetailsData,
  toc,
  setActiveTab,
}) {
  const prefill = useLocation().state || {}
  const [loading, setLoading] = useState(false)
  const [dateRange, setDateRange] = useState(null)
  const [odPairs, setOdPairs] = useState(null)
  const [parametersGroup, setParametersGroup] = useState({
    origin: prefill.origin,
    destination: prefill.destination,
    date: moment(prefill.date),
    includeAdvancePurchaseFares: false,
    showReturnFares: false
  })
  
  /*
  * Get valid dates to query on.
  */
  useEffect(() => {
    axiosInterceptor
      .get(`/api/journeys/daterange?toc=${toc.code}`)
      .then((response) => {
        setDateRange(response.data.dates)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  /*
  * Request queryable OD pairs.
  */
  useEffect(() => {
    axiosInterceptor
      .get(`/api/journeys/odpairs?toc=${toc.code}`)
      .then((response) => {
        setOdPairs(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  /*
  * Request summary data.
  */
  function handleSubmit() {
    setLoading(true)
    setSummaryData(null)
    setDetailsData(null)

    const dateFrom = parametersGroup.date.format(`YYYY-MM-DD`)
    const advancedFares = parametersGroup.includeAdvancePurchaseFares ? 'ADV' : 'NO_ADV'
    const returnFares = parametersGroup.showReturnFares ? 'RET': 'NO_RET'    

    ReactGA.ga('send', 'event', {
      'eventCategory': 'RevenueProtection', 
      'eventAction': 'Run Parameters Tab Query', 
      'eventLabel': `${toc.code} ${parametersGroup.origin}->${parametersGroup.destination} on ${dateFrom} ${advancedFares} ${returnFares}`,
      'dimension2': parametersGroup.origin,
      'dimension3': parametersGroup.destination,
      'dimension4': dateFrom,
      'dimension5': advancedFares,
      'dimension6': returnFares,
      'dimension7': `${parametersGroup.origin}->${parametersGroup.destination}`
    })

    axiosInterceptor
      .get(`/api/journeys/od?toc=${toc.code}&dateFrom=${dateFrom}&destination=${parametersGroup.destination}&origin=${parametersGroup.origin}&includeAdvancePurchaseFares=${parametersGroup.includeAdvancePurchaseFares}&showReturnFares=${parametersGroup.showReturnFares}&maxJourneys=1000`)
      .then((response) => {
        setLoading(false)
        if (response.data.error) {
          return
        }
        if (!response.data.outwardJourneySummaries) {
          return openErrorNotification(`There were no journeys available for this OD pair. Please try a different OD pair.`)
        }
        if (parametersGroup.showReturnFares && !response.data.returnJourneySummaries) {
          return openErrorNotification(`There were no return journeys available for this OD pair. Please try a different OD pair.`)
        }
        setSummaryData({
          ...response.data,
        })
        setActiveTab(`summary`)
      })
      .catch((error) => {
        setLoading(false)
      })
  }

  /*
  * Open error message.
  */
  function openErrorNotification(description) {
    notification[`error`]({
      message: `Error`,
      description,
    })
  }

  /*
  * Return invalid dates for <DatePicker />.
  */
  function disabledDate(dateToCheck) {
    return dateRange && !dateRange.includes(dateToCheck.format('YYYY-MM-DD'));
  }

  /*
  * Check if Advanced Fares are valid to query on.
  */
  function advancedPurchaseFaresDisabled() {
    const disabled = (
      !odPairs.advancePurchaseOdPairs 
      || !parametersGroup.origin 
      || !parametersGroup.destination 
      || !odPairs.advancePurchaseOdPairs.some(pair => pair.origin === parametersGroup.origin && pair.destination === parametersGroup.destination)
      || !parametersGroup.date.isSame(`2019-11-13`, `day`)
    )
    if (disabled && parametersGroup.includeAdvancePurchaseFares) {
      setParametersGroup({
        ...parametersGroup,
        includeAdvancePurchaseFares: false
      })
    }
    return disabled
  }

  return <div className="RevenueProtectionPageParametersComponent">
    <Card title="Parameters" loading={!odPairs || !dateRange}>
      {odPairs &&
        <React.Fragment>
          <div className="wrapper">
            <div>
              <div style={{marginBottom: 8}}>Origin Station</div>
              <AutoComplete
                style={{ width: "100%", fontSize: "12px" }}
                dataSource={Object.keys(odPairs.odPairs)}
                placeholder="Origin Station"
                filterOption={(inputValue, option) => 
                  option.props.children
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
                value={parametersGroup.origin}
                onChange={(e) =>
                  setParametersGroup({
                    ...parametersGroup,
                    origin: e.toUpperCase(),
                    destination: null
                  })
                }
              />
            </div>
            <div>
              <div style={{marginBottom: 8}}>Destination Station</div>
              <AutoComplete
                style={{ width: "100%", fontSize: "12px"  }}
                placeholder="Destination Station"
                dataSource={odPairs.odPairs[parametersGroup.origin]}
                filterOption={(inputValue, option) => 
                  option.props.children
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
                value={parametersGroup.destination}
                onChange={(e) =>
                  setParametersGroup({
                    ...parametersGroup,
                    destination: e.toUpperCase(),
                })}
              />
            </div>
            <div>
              <div style={{marginBottom: 8}}>Date</div>
              <DatePicker
                style={{width: `100%`}}
                value={parametersGroup.date}
                disabledDate={disabledDate}
                onChange={(e) => {
                  setParametersGroup({
                    ...parametersGroup,
                    date: e,
                  })
                }}
              />
            </div>
            <div>
              <div style={{marginBottom: 8}}>Advanced Fares</div>
              <div className="checkbox">
                <Checkbox
                  checked={parametersGroup.includeAdvancePurchaseFares}
                  disabled={advancedPurchaseFaresDisabled()}
                  onChange={(e) => {
                    setParametersGroup({
                      ...parametersGroup,
                      includeAdvancePurchaseFares: e.target.checked
                    })
                  }}
                >
                  Include Advance Purchase Fares
                </Checkbox>
              </div>
            </div>
            <div>
              <div style={{marginBottom: 8}}>Return Fares</div>
              <div className="checkbox">
                <Checkbox
                  checked={parametersGroup.showReturnFares}
                  onChange={(e) => {
                    setParametersGroup({
                      ...parametersGroup,
                      showReturnFares: e.target.checked
                    })
                  }}
                >
                  Include Return Fares
                </Checkbox>
              </div>
            </div>
          </div>
          <div>
            <Button 
              type="primary"
              loading={loading}
              disabled={
                !parametersGroup.origin
                || !odPairs.odPairs.hasOwnProperty(parametersGroup.origin)
                || !parametersGroup.destination
                || !odPairs.odPairs[parametersGroup.origin].includes(parametersGroup.destination)
                || !parametersGroup.date
              }
              onClick={() => handleSubmit()}
            >
              Submit
            </Button>
          </div>
        </React.Fragment>
      }
    </Card>
  </div>
}
