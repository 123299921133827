import React, { useState, useEffect } from 'react'
import ReactGA from 'react-ga'
import './top-actions.page.scss'
import { axiosInterceptor } from '../../core/axios-interceptor'
import moment from 'moment'
import { Table, Card, DatePicker, Button, Checkbox, notification } from 'antd'
import { Link } from 'react-router-dom'

const { RangePicker } = DatePicker

export function TopActionsPage({ 
  sidenav, 
  toc,
}) {
  const [datesData, setDatesData] = useState(null)
  const [tableData, setTableData] = useState(null)
  const [dates, setDates] = useState([moment(), moment()])
  const [loading, setLoading] = useState(false)
  const [loadingCsv, setLoadingCsv] = useState(false)
  const [includeAdvancePurchaseFares, setIncludeAdvancePurchaseFares] = useState(false)
  const dateFormat = `YYYY-MM-DD`
  const columns = [
    {
      title: `Date`,
      dataIndex: `date`,
      sorter: (a, b) => a.date.localeCompare(b.date),
      sortDirections: [`ascend`, `descend`],
      render: (text) => {
        return <div style={{whiteSpace: 'nowrap'}}>{text}</div>
      }
    },
    {
      title: `Origin`,
      dataIndex: `origin`,
      sorter: (a, b) => a.origin.localeCompare(b.origin),
      sortDirections: [`ascend`, `descend`],
    },
    {
      title: `Destination`,
      dataIndex: `destination`,
      sorter: (a, b) => a.destination.localeCompare(b.destination),
      sortDirections: [`ascend`, `descend`],
    },
    {
      title: `Average Saving (£)`,
      dataIndex: `averageFareDifference`,
      sorter: (a, b) => removePound(a.averageFareDifference) - removePound(b.averageFareDifference),
      sortDirections: [`ascend`, `descend`],
      render: (text) => {
        const numberWithoutPound = removePound(text)
        return <div style={getColorStyle(numberWithoutPound, 5, 10)}>{text}</div>
      }
    },
    {
      title: `Average Saving (%)`,
      dataIndex: `averageFareDifferencePercentage`,
      sorter: (a, b) => removePercentage(a.averageFareDifferencePercentage) - removePercentage(b.averageFareDifferencePercentage),
      sortDirections: [`ascend`, `descend`],
      render: (text) => {
        const numberWithoutPound = removePercentage(text)
        return <div style={getColorStyle(numberWithoutPound, 10, 20)}>{text}</div>
      }
    },
    {
      title: `Average Split Risk`,
      dataIndex: `averageSplitRisk`,
      sorter: (a, b) => removePound(a.averageSplitRisk) - removePound(b.averageSplitRisk),
      sortDirections: [`ascend`, `descend`],
      render: (text) => {
        const numberWithoutPound = removePound(text)
        return <div style={getColorStyle(numberWithoutPound, 1, 2)}>{text}</div>
      }
    },
    {
      title: `Current Earnings`,
      dataIndex: `adjustedEarnings`,
      sorter: (a, b) => removePound(a.adjustedEarnings) - removePound(b.adjustedEarnings),
      sortDirections: [`ascend`, `descend`]
    },
    {
      title: `Operating Journeys`,
      dataIndex: `operatingJourneys`,
      sorter: (a, b) => removeDash(a.operatingJourneys) - removeDash(b.operatingJourneys),
      sortDirections: [`ascend`, `descend`],
    },
    {
      title: `Average Competitor Cheapest Fare`,
      dataIndex: `averageCompetitorCheapestFare`,
      sorter: (a, b) => removePound(a.averageCompetitorCheapestFare) - removePound(b.averageCompetitorCheapestFare),
      sortDirections: [`ascend`, `descend`],
      render: (text) => {
        const numberWithoutPound = removePound(text)
        return <div style={getColorStyleReverse(numberWithoutPound, 5, 10)}>{text}</div>
      },
      hide: true
    },
    {
      title: `Average Toc Cheapest Fare`,
      dataIndex: `averageTocCheapestFare`,
      sorter: (a, b) => removePound(a.averageTocCheapestFare) - removePound(b.averageTocCheapestFare),
      sortDirections: [`ascend`, `descend`],
      render: (text) => {
        const numberWithoutPound = removePound(text)
        return <div style={getColorStyle(numberWithoutPound, 5, 10)}>{text}</div>
      },
      hide: true
    },
    {
      title: `Average Split Fare`,
      dataIndex: `averageSplitFare`,
      sorter: (a, b) => a.averageSplitFare - b.averageSplitFare,
      sortDirections: [`ascend`, `descend`],
      render: (text) => {
        const numberWithoutPound = removePound(text)
        return <div style={getColorStyleReverse(numberWithoutPound, 25, 50)}>{text}</div>
      },
      hide: true
    },
    {
      title: `Average Commission (£)`,
      dataIndex: `averageCommission`,
      sorter: (a, b) => removePound(a.averageCommission) - removePound(b.averageCommission),
      sortDirections: [`ascend`, `descend`],
      render: (text) => {
        const numberWithoutPound = removePound(text)
        return <div style={getColorStyleReverse(numberWithoutPound, 25, 50)}>{text}</div>
      },
      hide: true
    },
    {
      title: `Average Commission (£)`,
      dataIndex: `averageCommission`,
      sorter: (a, b) => removePound(a.averageCommission) - removePound(b.averageCommission),
      sortDirections: [`ascend`, `descend`],
      render: (text) => {
        const numberWithoutPound = removePound(text)
        return <div style={getColorStyleReverse(numberWithoutPound, 25, 50)}>{text}</div>
      },
      hide: true
    },
    {
      title: `Cheapest Split Fare Commission (£)`,
      dataIndex: `splitFareCommission`,
      sorter: (a, b) => removePound(a.splitFareCommission) - removePound(b.splitFareCommission),
      sortDirections: [`ascend`, `descend`],
      render: (text) => {
        const numberWithoutPound = removePound(text)
        return <div style={getColorStyleReverse(numberWithoutPound, 25, 50)}>{text}</div>
      },
      hide: true
    },
    {
      title: `Average Commission (%)`,
      dataIndex: `averageCommissionPercentage`,
      sorter: (a, b) => removePercentage(a.averageCommissionPercentage) - removePercentage(b.averageCommissionPercentage),
      sortDirections: [`ascend`, `descend`],
      render: (text) => {
        const numberWithoutPound = removePercentage(text)
        return <div style={getColorStyleReverse(numberWithoutPound, 30, 60)}>{text}</div>
      },
      hide: true
    },
    {
      title: `Cheapest Split Fare Commission (%)`,
      dataIndex: `splitFareCommissionPercent`,
      sorter: (a, b) => removePercentage(a.splitFareCommissionPercent) - removePercentage(b.splitFareCommissionPercent),
      sortDirections: [`ascend`, `descend`],
      render: (text) => {
        const numberWithoutPound = removePercentage(text)
        return <div style={getColorStyleReverse(numberWithoutPound, 30, 60)}>{text}</div>
      },
      hide: true
    },
    {
      title: 'Revenue Protection',
      key: 'revenueProtection',
      render: (text, record) => (
      <span>
        <Link to={{pathname:"/revenue-protection",
          state: {
            origin: record.origin,
            destination: record.destination,
            date: record.date
          }}}>
          Details
        </Link>
      </span>
      )
    }
  ]

  /*
  * Request queryable dates data.
  */
  useEffect(() => {
    axiosInterceptor
      .get(`/api/journeys/daterange?toc=${toc.code}`)
      .then(response => {
        setDatesData(response.data)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  /*
  * Request Top Actions data.
  */
  function getData() {
    setLoading(true)

    const dateFrom = dates[0].format(dateFormat)
    const dateTo = dates[1].format(dateFormat)

    ReactGA.event({category: 'TopActions', action: 'GetData', label: `(${toc.code}) ${dateFrom} to ${dateTo}`})

    axiosInterceptor
      .get(`/api/journeys/top?toc=${toc.code}&dateFrom=${dateFrom}&dateTo=${dateTo}&includeAdvancePurchaseFares=${includeAdvancePurchaseFares}`)
      .then(res => {
        setLoading(false)
        setTableData(parseResponse(res.data))
      })
      .catch(err => {
          setLoading(false)
      })
  }

  function downloadTopActions() {
    setLoadingCsv(true)
    axiosInterceptor
      .get(`/api/journeys/topcsv?toc=${toc.code}&dateFrom=${dates[0].format(dateFormat)}&dateTo=${dates[1].format(dateFormat)}&includeAdvancePurchaseFares=${includeAdvancePurchaseFares}`)
      .then((response) => {
        setLoadingCsv(false)
        if (response.data.error) {
          return
        }
        const blob = new Blob(["\ufeff", response.data], {type: 'text/csv'});
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, "TopActions.csv");
        } else {
          const a = document.createElement('a');
          document.body.appendChild(a);
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = "TopActions.csv";
          a.click();
          setTimeout(() => {
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
          }, 0)
        }
      })
      .catch((error) => {
          setLoadingCsv(false)
      })
  }

  function parseResponse(data) {
    return data.map((value, index) => ({
      key: index,
      date: value.date.split("T")[0],
      origin: value.origin.crs,
      destination: value.destination.crs,
      averageCompetitorCheapestFare: addPound(value.avgCompetitorCheapestFare),
      averageTocCheapestFare: addPound(value.avgTocCheapestFare),
      averageFareDifference: addPound(value.avgFareDifference),
      averageFareDifferencePercentage: addPercentage(value.avgFareDifferencePercentage),
      averageCommission: addPound(value.avgCommission),
      averageCommissionPercentage: addPercentage(value.avgCommissionPercentage),
      averageSplitRisk: addPound(value.avgSplitRisk),
      averageSplitFare: addPound(value.avgSplitFare),
      averageTimeliness: value.avgTimeliness,
      splitFareCommission: addPound(value.cheapestSplitFareCommission),
      splitFareCommissionPercent: addPercentage(value.cheapestSplitFareCommissionPercent),
      adjustedEarnings: addPoundNoLimit(value.adjustedEarnings),
      operatingJourneys: value.operatingJourneys === -1 ? "-" : value.operatingJourneys
    }))
  }

  function getColorStyle(amount, greenMaxVal, amberMaxVal) {
    return amount <= greenMaxVal 
      ? { color: '#2fc25b' } 
      : amount <= amberMaxVal 
      ? { color: '#facc14' } 
      : { color: '#f5222d' }
  }

  function getColorStyleReverse(amount, redMaxVal, amberMaxVal) {
    return amount <= redMaxVal 
      ? { color: '#f5222d' } 
      : amount <= amberMaxVal 
      ? { color: '#facc14' } 
      : { color: '#2fc25b' }
  }

  function removeDash(value) {
    if (value === `-`) return Infinity
    return parseInt(value)
  }

  function addPoundNoLimit(value) {
    if (value === -1) return `-`
    return `£${value.toLocaleString()}`
  }

  function addPound(value) {
    if (value < 0)
    {
      value = Math.abs(value)
      return `-£${value.toFixed(2)}`
    }
    if (value > 10000) return `-`
    return `£${value.toFixed(2)}`
  }

  function removePound(value) {
    if (value === `-`) return Infinity
    value = value.replace(/,/g,'')
    return parseInt(value.replace(/£/gi, ``))
  }

  function addPercentage(value) {
    if (value > 10000) return `-`
    return `${value.toFixed(2)}%`
  }

  function removePercentage(value) {
    if (value === `-`) return Infinity
    return parseInt(value.replace(/%/gi, ``))
  }

  function checkDateInRange(dateToCheck) {
    const startDate = moment(datesData.startDate, `DD-MM-YYYY`)
    const range = parseInt(datesData.daysToProcess, 10)
    return dateToCheck && (dateToCheck < startDate || dateToCheck > startDate.add(range, `day`))
  }

  return (
    <div 
      className="TopActionsPage" 
      style={{ 
        maxWidth: `calc(100vw - ${sidenav ? '304px' : '48px'})`
      }}
    >
      <Card 
        title="Parameters" 
        loading={!datesData} 
        style={{ marginBottom: 24 }}
      >
        <div className="parameters">
          <RangePicker 
            defaultValue={dates} 
            disabledDate={checkDateInRange} 
            onChange={(dates) => setDates(dates)} 
          />
          <Checkbox 
            defaultChecked={false}
            disabled={true}
            onChange={(includeAdvancePurchaseFares) => {
              setIncludeAdvancePurchaseFares(includeAdvancePurchaseFares.target.checked)
            }}
          >
            Incl. Adv. Purchase Fares?
          </Checkbox>
          <Button 
            type="primary" 
            loading={loading} 
            onClick={getData} 
            style={{ marginLeft: 12 }}
          >
            Submit
          </Button>
        </div>
      </Card>
      {tableData &&
        <Card
            title="Top Actions"
            extra={
              <Button
                type="primary"
                loading={loadingCsv}
                disabled={true}
                onClick={() => downloadTopActions()}
              >
                Export
              </Button>
            }
        >
          <Table 
            bordered 
            columns={columns.filter(column => !column.hide)}
            dataSource={tableData}
            loading={loading}
            pagination={{pageSize:100}}
          />
        </Card>
      }
    </div>
  )
}