import React, { useState, useEffect } from 'react'
import './matrix.components.scss'
import { Card, Table, Icon, Select } from 'antd'
import { 
  to24HourTime, 
  addPound, 
  parseBigNumber, 
  calcPercentDiff,
  getTotalCommission
} from '../../../../core/transformation-functions'
import moment from 'moment'

const { Option } = Select

export function MatrixComponent({
  summaryData,
  setDetailsData,
  setActiveTab,
  awfulTempHack,
}) {

  const journeyTableColumns = [
    {
      title: `Type`,
      dataIndex: `type`,
      key: `type`
    },
    {
      title: `Date`,
      dataIndex: `date`,
      key: `date`
    },
    {
      title: `Origin`,
      dataIndex: `origin`,
      key: `origin`
    },
    {
      title: `Destination`,
      dataIndex: `destination`,
      key: `destination`
    },
    {
      title: `Journeys`,
      dataIndex: `journeys`,
      key: `journeys`
    },
  ]
  const financialTableColumns = [
    {
      title: `Type`,
      dataIndex: `type`,
      key: `type`
    },
    {
      title: `Date`,
      dataIndex: `date`,
      key: `date`
    },
    {
      title: `Operating Journeys`,
      dataIndex: `operatingJourneys`,
      key: `operatingJourneys`
    },
    {
      title: `Current Earnings`,
      dataIndex: `currentEarnings`,
      key: `currentEarnings`
    },
    {
      title: `Expected Earnings`,
      dataIndex: `expectedEarnings`,
      key: `expectedEarnings`
    },
  ]
  const [journeyTableData, setJourneyTableData] = useState(null)
  const [financialTableData, setFinancialTableData] = useState(null)
  const [matrixData, setMatrixData] = useState(null)
  const [matrixMenu, setMatrixMenu] = useState(true)
  const [matrixOptions, setMatrixOptions] = useState(
    JSON.parse(localStorage.getItem(`matrixOptions`)) 
    || { formula: `commission`, difference: `percentage`, range: `all`}
  )

  useEffect(() => {
    if (!summaryData) {
      setMatrixData(null)
      setJourneyTableData(null)
      setMatrixMenu(true)
    }
  }, [summaryData])

  useEffect(() => {
    if (summaryData) {
      console.log(summaryData)
      setJourneyTableData(parseJourneyTableData(summaryData))
      setFinancialTableData(parseFinancialTableData(summaryData))
      setMatrixData(parseMatrixData(summaryData))
    }
  }, [summaryData])

  useEffect( () => {
    if (matrixOptions) {
      localStorage.setItem(`matrixOptions`, JSON.stringify(matrixOptions))
    }
  }, [matrixOptions])

  function parseJourneyTableData(data) {
    const tableData = []
    if (data.outwardJourneySummaries && data.outwardJourneySummaries.length) {
      tableData.push({
        key: `1`,
        type: `Outward`,
        date: data.date,
        origin: data.outwardJourneySummaries[0].callingPoints[0].location.crs,
        destination: data.outwardJourneySummaries[0].callingPoints[data.outwardJourneySummaries[0].callingPoints.length - 1].location.crs,
        journeys: data.outwardJourneySummaries.length,
      })
    }
    if (data.returnJourneySummaries && data.returnJourneySummaries.length) {
      tableData.push({
        key: `2`,
        type: `Return`,
        date: data.date,
        origin: data.returnJourneySummaries[0].callingPoints[0].location.crs,
        destination: data.returnJourneySummaries[0].callingPoints[data.returnJourneySummaries[0].callingPoints.length - 1].location.crs,
        journeys: data.returnJourneySummaries.length,
      })
    }
    return tableData
  }

  function parseFinancialTableData(data) {
    const tableData = []
    if (data.outwardAdjustedEarnings && data.outwardOperatingJourneys) {
      let operatingJourneys = `N/A`
      let currentEarnings = `N/A`
      let expectedEarnings = `N/A`
      if (data.outwardOperatingJourneys > 0) {
        operatingJourneys = parseBigNumber(data.outwardOperatingJourneys)
      }
      if (data.outwardAdjustedEarnings > 0) {
        currentEarnings = parseBigNumber(addPound(data.outwardAdjustedEarnings))
      }
      if (data.outwardExpectedEarnings > 0) {
        expectedEarnings = parseBigNumber(addPound(data.outwardExpectedEarnings))
      }
      tableData.push({
        key: `1`,
        type: `Outward`,
        date: data.date,
        operatingJourneys,
        currentEarnings,
        expectedEarnings,
      })
    }
    if (data.returnJourneySummaries  && data.returnJourneySummaries.length && data.returnAdjustedEarnings && data.returnOperatingJourneys) {
      let operatingJourneys = `N/A`
      let currentEarnings = `N/A`
      let expectedEarnings = `N/A`
      if (data.returnOperatingJourneys > 0) {
        operatingJourneys = parseBigNumber(data.returnOperatingJourneys)
      }
      if (data.returnAdjustedEarnings > 0) {
        currentEarnings = parseBigNumber(addPound(data.returnAdjustedEarnings))
      }
      if (data.returnExpectedEarnings > 0) {
        expectedEarnings = parseBigNumber(addPound(data.returnExpectedEarnings))
      }
      tableData.push({
        key: `2`,
        type: `Return`,
        date: data.date,
        operatingJourneys,
        currentEarnings,
        expectedEarnings,
      })
    }
    return tableData
  }

  function parseMatrixData(data) {
    if (!data.outwardJourneySummaries || !data.outwardJourneySummaries.length) { return null }

    const matrixData = []
    const maxFares = Math.max(...data.outwardJourneySummaries.map(o => o.returnFares ? o.returnFares.length : 0));

    for (let outwardJourney of data.outwardJourneySummaries) {
      const time = to24HourTime(outwardJourney.departTime || outwardJourney.arrivalTime)
      const id = outwardJourney.journeyId
      const fares = []

      // Outward Journeys
      if (!outwardJourney.returnFares || outwardJourney.returnFares.length === 0) {
        const splits = []

        if (outwardJourney.fareListSplit) {
          for (let fare of outwardJourney.fareListSplit) {
            splits.push({
              totalPrice: fare.price,
              outwardFare: fare,
              returnFare: null
            })
          }
        }

        // If only doing outward, create fake returnfares for the matrix
        if (!data.returnJourneySummaries || data.returnJourneySummaries.length === 0) {
          outwardJourney.returnFares = [{
            returnJourneyId: null,
            cheapestDirect: (outwardJourney.fareListDirect && outwardJourney.fareListDirect[0] ? [outwardJourney.fareListDirect[0]] : []),
            cheapestReturnPrice: 0,
            splits,
          }]
        }
        else { // We don't have a return journey for this outward, don't return it
          continue
        }
      }

      const startingFareInsertionIndex = maxFares - (outwardJourney.returnFares ? outwardJourney.returnFares.length : 0);

      for (let i = 0; i < startingFareInsertionIndex; i++) {
        fares.push({valid: false})
      }

      // Calculate risk for returnfares
      for (let returnFare of outwardJourney.returnFares) {
        let directFares = null
        let splitFares = null
        let returnJourney = null
        let risks = {
          commissionPercentage: 0,
          commissionPrice: 0,
          farePercentage: 0,
          farePrice: 0,
          showRisk: false,
          overallRisk: 0
        }

        // Return Journey
        if (returnFare.returnJourneyId && data.returnJourneySummaries && data.returnJourneySummaries.length) {
          for (let journey of data.returnJourneySummaries) {
            if (journey.journeyId === returnFare.returnJourneyId) {
              returnJourney = {
                id: journey.journeyId,
                time: to24HourTime(journey.departTime || journey.arrivalTime),
              }
            }
          }
        }


        // Direct Fares
        if (returnFare.cheapestDirect && returnFare.cheapestDirect.length) {
          const price = returnFare.cheapestDirect.reduce((price, fare) => {
            return price + fare.price
          }, 0)
          const commission = returnFare.cheapestDirect.reduce((commission, fare) => {
            return commission + fare.commission
          }, 0)

          directFares = [{
            price,
            commission,
          }]
        }

        // Split Fares
        if (returnFare.splits && returnFare.splits.length) {
          splitFares = []

          for (let splitFare of returnFare.splits) {
            splitFares.push({
              price: splitFare.totalPrice,
              commission: getTotalCommission(
                [...(splitFare.outwardFare ? splitFare.outwardFare.splits : []),
                  ...(splitFare.returnFare ? splitFare.returnFare.splits : [])]),
            })
          }
        }

        // Risks
        if (splitFares && splitFares.length && directFares && directFares.length) {
          const commissionPercentage = calcPercentDiff(splitFares[0].commission, directFares[0].commission)
          const farePercentage = calcPercentDiff(splitFares[0].price, directFares[0].price)
          const commissionPrice = Math.round(splitFares[0].commission - directFares[0].commission)
          const farePrice = Math.round(splitFares[0].price - directFares[0].price)
          const overallRiskPercentage = farePercentage < 0 ? commissionPercentage : 0
          const overallRiskPrice = farePercentage < 0 ? commissionPrice : 0

          risks = {
            commissionPercentage,
            commissionPrice,
            farePercentage,
            farePrice,
            overallRiskPercentage: overallRiskPercentage,
            overallRiskPrice: overallRiskPrice,
            showRisk: true
          }
        }

        fares.push({
          directFares,
          splitFares,
          returnJourney,
          risks,
          valid: true,
        })
      }

      matrixData.push({
        time,
        id,
        fares,
      })
    }

    console.log(matrixData)
    
    return matrixOptions.range === `am`
      ? matrixData.filter((v) => moment(v.time, "HH:mm").isBefore(moment("12:00", "HH:mm")))
      : matrixOptions.range === `pm`
        ? matrixData.filter((v) => moment(v.time, "HH:mm").isAfter(moment("11:59", "HH:mm")))
        : matrixData
  }

  function handleSubmit(journey, fare) {   
    if (!fare.valid) { return }

    const data = {
      outwardData: null,
      returnData: null,
      directFares: null,
      splitFares: null,
      awfulTempHack: awfulTempHack
    }

    if (summaryData.outwardJourneySummaries && summaryData.outwardJourneySummaries.length && journey && journey.id) {
      data.outwardData = summaryData.outwardJourneySummaries.find((v) => v.journeyId === journey.id)
    }

    if (summaryData.returnJourneySummaries && summaryData.returnJourneySummaries.length && fare && fare.returnJourney && fare.returnJourney.id) {
      data.returnData = summaryData.returnJourneySummaries.find((v) => v.journeyId === fare.returnJourney.id)
    }

    if (summaryData.returnJourneySummaries && summaryData.returnJourneySummaries.length && fare && fare.returnJourney && fare.returnJourney.id) {
      data.splitFares = data.outwardData.returnFares.find((v) => v.returnJourneyId === fare.returnJourney.id).splits || []
      data.directFares = data.outwardData.returnFares.find((v) => v.returnJourneyId === fare.returnJourney.id).cheapestDirect || []

      if (data.directFares.length === 1) {
        data.directFares.push({
          ...data.directFares[0],
          origin: data.directFares[0].destination,
          destination: data.directFares[0].origin,
        })
      }
    }

    if(data.awfulTempHack != -1)
    {
      data.awfulTempHack = data.awfulTempHack + 1
    }
    else{
      data.awfulTempHack = 0
    }

    setDetailsData(data)
    setActiveTab(`details`)
  }

  function getCellBackground(risks) {
    function formatRiskBackground(type, percentage, price) {
      let number = 0;
      if (type === `percentage`) {
        number = percentage;
      }
      else {
        number = price;
      }
      return number >= 0
        ? `rgb(82, 196, 26)`
        : number >= -25
          ? `rgba(250, 214, 20, 1)`
          : number >= -50
            ? `rgb(250, 173, 20)`
            : `rgb(250, 84, 28)`
    }

    if (risks.showRisk === false) {
      return `rgba(70, 70, 30, 0.1)`
    }

    switch(matrixOptions.formula) {
      case "overallRisk": {
        return formatRiskBackground(matrixOptions.difference, risks.overallRiskPercentage, risks.overallRiskPrice)
      }
      case "commission": {
        return formatRiskBackground(matrixOptions.difference, risks.commissionPercentage, risks.commissionPrice)
      }
      case "price": {
        return formatRiskBackground(matrixOptions.difference, risks.farePercentage, risks.farePrice)
      }
    }
  }

  function getCellValue(risks) {
    function formatRiskValue(type, percentage, price) {
      if (type === 'percentage') {
        return percentage + `%`
      }
      return (`£` + price).replace(`£-`, `-£`)
    }

    if (risks.showRisk === false) {
      return `-`
    }

    switch(matrixOptions.formula) {
      case "overallRisk": {
        return formatRiskValue(matrixOptions.difference, risks.overallRiskPercentage, risks.overallRiskPrice)
      }
      case "commission": {
        return formatRiskValue(matrixOptions.difference, risks.commissionPercentage, risks.commissionPrice)
      }
      case "price": {
        return formatRiskValue(matrixOptions.difference, risks.farePercentage, risks.farePrice)
      }
    }
  }

  return (matrixData && 
    <div className="RevenueProtectionPageMatrixComponent">
      <div className="cards">
        <Card title="Journey Details">
          <Table 
            columns={journeyTableColumns} 
            dataSource={journeyTableData} 
            bordered 
            size="small" 
            pagination={false} 
          />
        </Card>
        <Card title="Financial Summary">
          <Table 
            columns={financialTableColumns} 
            dataSource={financialTableData} 
            bordered 
            size="small" 
            pagination={false} 
          />
        </Card>
      </div>
      <Card 
        title="Journey Risk Assessment"
        extra={
          <div className="card-actions">
            <Icon 
              type={matrixMenu ? `menu-unfold` : `menu-fold`} 
              onClick={(e) => setMatrixMenu(!matrixMenu)}
            />
          </div>
        }
      >
        <div style={{ display: `flex` }}>
          <div 
            className="matrix" 
            style={{ 
              paddingTop: 16, 
              paddingLeft: summaryData.returnJourneySummaries ? 16 : 0 
            }}>
            <div className="x-label">Outward Journeys</div>
            {summaryData.returnJourneySummaries && 
              <div className="y-label">Return Journeys</div>
            }
            <div className="x-axis">
              {summaryData.returnJourneySummaries && 
                <div className="heading">Times</div>
              }
              {matrixData.map((journey, index) =>
                <div 
                  className="heading"
                  key={index}
                >
                  {journey.time}
                </div>
              )}
            </div>
            <div className="cells">
              {summaryData.returnJourneySummaries &&
                <div className="column">
                  {matrixData[0].fares.map((fare, index) =>
                    <div 
                      className="cell" 
                      style={{
                        background: `#fff`,
                        cursor: `default`,
                        opacity: 1,
                      }}
                      key={index}
                    >
                      {fare.returnJourney && fare.returnJourney.time}
                    </div>
                  )}
                </div>
              }
              {matrixData.map((journey, journeyIndex) =>
                <div 
                  className="column"
                  key={journeyIndex}
                >
                  {journey.fares.map((fare, fareIndex) =>
                    <div 
                      className="cell"
                      style={{
                        background: fare.valid ? getCellBackground(fare.risks) : null,
                        cursor: fare.valid ? `pointer` : `default`,
                      }}
                      key={fareIndex}
                      onClick={(e) => {
                        handleSubmit(journey, fare)
                      }}
                    >
                      {fare.valid && getCellValue(fare.risks)}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {matrixMenu &&
            <div className="side-menu">
              <div className="legend">
                <div className="heading">Risk Levels</div>
                <div className="blocks">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
              <div className="option">
                <div className="heading">Calculate Difference From</div>
                <div className="form">
                  <Select 
                    value={matrixOptions.formula} 
                    onChange={(e) => {
                      setMatrixOptions({
                        ...matrixOptions,
                        formula: e
                      })
                    }}
                  >
                    <Option value="overallRisk">Overall Risk</Option>
                    <Option value="price">Fare Price</Option>
                    <Option value="commission">Fare Commission</Option>
                  </Select>
                </div>
              </div>
              <div className="option">
                <div className="heading">Display Difference As</div>
                <div className="form">
                  <Select 
                    value={matrixOptions.difference} 
                    onChange={(e) => {
                      setMatrixOptions({
                        ...matrixOptions,
                        difference: e
                      })
                    }}
                  >
                    <Option value="percentage">Percentage</Option>
                    <Option value="pound">Pound</Option>
                  </Select>
                </div>
              </div>
              <div className="option">
                <div className="heading">Display Outward Times</div>
                <div className="form">
                  <Select 
                    value={matrixOptions.range} 
                    onChange={(e) => {
                      setMatrixOptions({
                        ...matrixOptions,
                        range: e
                      })
                    }}
                  >
                    <Option value="am">AM</Option>
                    <Option value="pm">PM</Option>
                    <Option value="all">All</Option>
                  </Select>
                </div>
              </div>
            </div>
          }
        </div>
      </Card>
    </div>
  )
}