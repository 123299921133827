import React from 'react'
import './footer.component.scss'

export function FooterComponent() {  
  return (
    <div className="FooterComponent">
      © 2019 SilverRail Technologies. All Rights Reserved. <br /> 
      SILVERRAIL is a registered trademark of SilverRail Technologies.
    </div>
  )
}