import React, { useState, useEffect } from 'react'
import './bar-chart.component.scss'

export function BarChartComponent({ 
  detailsData  
}) {
  const [parsedData, setParsedData] = useState(null)

  useEffect(() => {
    const doReturn = !!detailsData.returnData
    const outwardPoints = detailsData.outwardData.callingPoints
    const returnPoints = (detailsData.returnData || {}).callingPoints || []
    const origin = outwardPoints[0].location.crs;
    const dest = outwardPoints[outwardPoints.length-1].location.crs;

    const callingPoints = [...outwardPoints, ...returnPoints]
      .filter(point => point.location.crs != origin && point.location.crs != dest)
      .reduce((callingPoints, callingPoint) => {        
        callingPoints[callingPoint.location.crs] = doReturn ? {outward: 0, return: 0} : {outward: 0}
        return callingPoints
      }, {});    

    (doReturn ? detailsData.splitFares : detailsData.outwardData.fareListSplit)
      .forEach((fare) => {
        (doReturn ? fare.outwardFare.splits : fare.splits)
          .forEach((splitFare) => {
            const departCrs = splitFare.origin.crs
            if (departCrs !== origin) {
              callingPoints[departCrs].outward += 1
            }
          });          

          (doReturn ? fare.returnFare.splits : [])
          .forEach((splitFare) => {
            const departCrs = splitFare.origin.crs
            if (departCrs != dest) {
              callingPoints[departCrs].return += 1
            }
          })
      })

    const numFares = (doReturn ? detailsData.splitFares.length : detailsData.outwardData.fareListSplit.length)
    Object.values(callingPoints)
      .forEach((callingPoint) => {
        callingPoint.outwardPercent = callingPoint.outward/numFares * 100        
        callingPoint.returnPercent = doReturn ? callingPoint.return/numFares * 100 : null        
      })

    setParsedData({
      xAxis: Object.keys(callingPoints),
      yAxis: [`100%`, `75%`, `50%`, `25%`, `0%`],
      bars: Object.values(callingPoints),
      doReturn: doReturn
    })        
    console.log(callingPoints)
  }, [detailsData])

  return parsedData &&    
      <div className="RevenueProtectionPageBarChartComponent">
      {parsedData.doReturn &&
        <div className="labels">
            <div className="label">
                <div className="dot"></div>
                <div>outward</div>
            </div>
            <div className="label">
                <div className="dot"></div>
                <div>return</div>
            </div>
        </div>
      }    
      <div className="y-axis">
        {parsedData.yAxis.map((value, index) => 
          <div className="value" key={index}>
            {value}
          </div> 
        )}
      </div>
      <div className="body">
        <div className="graph">       
          {parsedData.bars.map((bar, index) => 
            <div className="bar" key={index}>
              <div 
                className="segment" 
                title={bar.outwardPercent + '%'}
                style={{
                  'height': bar.outwardPercent + '%'
                }}
              ></div>
              {!bar.returnPercent ||                                  
                <div 
                  className="segment" 
                  title={bar.returnPercent + '%'}
                  style={{
                    'height': bar.returnPercent + '%'                    
                  }}              
                ></div>               
              }             
            </div>  
          )}         
        </div>
        <div className="x-axis">
          {parsedData.xAxis.map((value, index) => 
            <div className="value" key={index}>
              {value}
            </div> 
          )}           
        </div>
      </div>
    </div>
}