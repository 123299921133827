import React from 'react'
import { useState } from 'react'
import './price-simulation.component.scss'
import { Card } from 'antd'
import {
  PieChartComponent,
  BarChartComponent,
  SplitFaresChartComponent,
  PriceSimulationChartComponent,
  PriceSimulationDetailsChartComponent,
} from './components'

export function PriceSimulationComponent({
  toc,
  detailsData,
}) {
  const [selectedFare, setSelectedFare] = useState(0);
  const [moddedData, setModdedData] = useState(JSON.parse(JSON.stringify(detailsData))); //this is the ultimate sin
  const [forceRerender, setForceRerender] = React.useState('pants');//terrible sin, but react hooks made me do this by not detecting the changed state

  if(detailsData.awfulTempHack != moddedData.awfulTempHack){
    setModdedData(detailsData)
  }

  const handleSplitClick = (fare) => {
    setSelectedFare(fare)
  }

  const updateModdedData = (newDetailData) => {
    setModdedData(newDetailData)
    setForceRerender(!forceRerender);
  }

  return (
    <div className="RevenueProtectionPagePriceSimulationComponent">
      {detailsData &&
        <div>
            <div 
              style={{ 
                display: `flex`, 
                margin: `24px -24px -24px 0` 
              }}
            >
          <Card title="Split Fares Chart"
                style={{
                  width: `calc(50.0% - 24px)`, 
                  margin: `0 24px 24px 0`
                }}>
            <SplitFaresChartComponent
              detailsData={detailsData}
              toc={toc}
              getClicked={handleSplitClick}
              selectedFare={selectedFare}
            />
          </Card>
          <Card title="Pricing Simulation Chart"
                style={{
                  width: `calc(50.0% - 24px)`, 
                  margin: `0 24px 24px 0`
                }}>          
            <PriceSimulationChartComponent
              detailsData={moddedData}
              toc={toc}
              modifyDetails={updateModdedData}
              selectedFare={selectedFare}
            />
          </Card>
          </div>
          {/* <div 
              style={{ 
                display: `flex`, 
                margin: `24px -24px -24px 0` 
              }}
            >
          <Card title="Pricing Simulation Details Chart"
                style={{
                  width: `calc(50.0% - 24px)`, 
                  margin: `0 24px 24px 0`
                }}>          
            <PriceSimulationDetailsChartComponent
              detailsData={detailsData}
              toc={toc}
              //getClicked={handleSplitClick}
              selectedFare={selectedFare}
            />
          </Card>
          </div> */}
          {detailsData.outwardData.fareListSplit &&
            <div 
              style={{ 
                display: `flex`, 
                margin: `24px -24px -24px 0` 
              }}
            >
              <Card
                title="Split Fares Average Revenue"
                style={{
                  width: `calc(33.3333% - 24px)`, 
                  margin: `0 24px 24px 0`
                }}
              >
                <PieChartComponent
                  data={detailsData.outwardData}
                />
              </Card>
              <Card
                title="Split Point Frequency"
                style={{ 
                  width: `calc(66.6666% - 24px)`, 
                  margin: `0 24px 24px 0` 
                }}
              >
                <BarChartComponent
                  data={detailsData.outwardData}
                />
              </Card>
            </div>
          }
        </div>
      }
    </div>
  )


}