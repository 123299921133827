import React, { useState, useEffect } from 'react'
import './pie-chart.component.scss'
import { addPercentage } from '../../../../../../core/transformation-functions'

export function PieChartComponent({ 
  data,
}) {
  const [parsedData, setParsedData] = useState(null)

  useEffect(() => {
    const fareSetters = data.fareListSplit
      .reduce((labels, fare) => {
        fare.splits.forEach((splitFare) => {
          labels[splitFare.fareSetter]
            ? labels[splitFare.fareSetter] += splitFare.price
            : labels[splitFare.fareSetter] = splitFare.price
        })
        return labels
      }, {})
    const segments = Object.entries(fareSetters)
      .sort(([kA, vA], [kB, vB]) => vA > vB ? -1 : 1)
      .reduce((segments, [key, val]) => {
        const offset = Object.values(segments).reduce((offset, segment) => offset + segment[`size`], 0)
        const size = val / Object.values(fareSetters).reduce((size, amount) => size + amount, 0) * 100
        const overHalf = size > 50 ? 1 : 0
        segments.push({ key, offset, size, overHalf })
        return segments
      }, [])
    
    setParsedData({
      segments,
    })
  }, [data])

  return parsedData &&
    <div className="RevenueProtectionPagePieChartComponent">
      <div className="graph">
        {parsedData.segments.map((segment, index) =>
          <div
            className="segment"
            key={index}
            style={{
              '--offset': segment.offset,
              '--size': segment.size,
              '--overHalf': segment.overHalf
            }}
          >
          </div>
        )}
        <div className="center">Revenue</div>
      </div>
      <div className="labels">
        {parsedData.segments.map((segment, index) =>
          <div className="label" key={index}>
            <div className="dot"></div>
            <div>{segment.key}</div>
            <div className="percentage">
              {addPercentage(segment.size)}
            </div>
          </div>
        )}
      </div>
    </div>
}