import React, { useState, useEffect } from 'react'
import './network-explorer.page.scss'
import { Link } from 'react-router-dom'
import { Card, Icon } from 'antd'
import { axiosInterceptor } from '../../core/axios-interceptor'

export function NetworkExplorerPage({ 
  user,
}) {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)

  useEffect(() => {
    getData()
  }, [])

  function getData() {
    setLoading(true)
    axiosInterceptor
      .get(`/api/journeys/info`)
      .then(res => {
        setLoading(false)
        setData(res.data)
      })
      .catch(err => {
        setLoading(false)
      })
  }

  function getExtra(name) {
    if (name === "Top Actions" && (user.Permissions||[]).includes("topActions")) {
      return <Link to="/top-actions">More</Link>
    }
    else if (name === "Revenue Protection" && (user.Permissions||[]).includes("revenueProtection")) {
      return <Link to="/revenue-protection">More</Link>
    }
    else {
      return <Icon type="lock" />
    }
  }

  return (
    <div className="NetworkExplorerPage">
      {!loading && data &&
        <div className="cards">
          {Object.keys(data.requestInfo).map((name, index) =>
            <Card
              className="card"
              title={name}
              extra={getExtra(name)}
              bordered={false}
              key={index}
            >
              <p>
                {data.requestInfo[name]}
              </p>
            </Card>
          )}
        </div>
      }
      {
        loading &&
        <div className="cards">
          {Array.from(Array(8).keys()).map((value, index) =>
            <Card className="card" key={index} loading={true}></Card>
          )}
        </div>
      }
    </div>
  )
}